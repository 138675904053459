@import 'node_modules/@hse-design/core/src/base';
@import 'src/vars';

.Header {
  position: absolute;
  z-index: var(--zindex-header);
  width: 100%;
}

.Header__backlink {
  margin: 0 $spacing-small 0 -$spacing-small-x;
}

.Header__divider {
  height: 16px;
  margin: 0 $spacing-small-x;
}

.Header__icon {
  margin: $spacing-small-3x;
  margin-right: -$spacing-small-x;
}

.Header__titleLink {
  padding-bottom: 0;
  border-bottom: none;
}

.Header__mode {
  display: flex;
  flex-direction: row;
}

.Header__titleMode {
  padding-right: $spacing-small-x;
}

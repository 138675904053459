@import 'src/vars';
@import 'src/common/components/MapControls/MapControls.module';

.GoToPinControl {
  @include clickableIcon;
  @extend %flex-c;
  @extend %control-container-default;
  position: absolute;
  bottom: $spacing-small;
  left: $spacing-small-2x;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: $spacing-large;
  margin-top: $spacing-small-3x;
  padding: $spacing-small-2x;
  transition: color 0.2s;
  pointer-events: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.GoToPinControl_mobile {
  bottom: 0;
  left: $spacing-small-3x;
}
.GoToPinControl_selected {
  background-color: #0050cf;
  svg {
    color: #fff;
  }
  &:hover {
    svg {
      color: #fff !important;
    }
  }
}

@import './MapperPage.module';

.MapperConfig {
  @extend %basePopup;
  position: absolute;
  right: 24px;
  bottom: 12px;
  padding: $spacing-small-2x;
}

.MapperConfig__title {
  @include text_caption_large_bold;
  margin: 0 0 0 $spacing-small-2x;
  padding: $spacing-small-2x;
}

.MapperConfig__container {
  display: flex;
  flex-direction: column;
}

.MapperConfig__container > * {
  margin: 8px 0;
}

.MapperConfig__toggleVisibilityButton {
  margin-left: auto;
}

@import 'node_modules/@hse-design/core/src/base';
@import 'src/vars';

.MapBox {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 300px;
  background: $color-background-gray-1;
}

.MapCanvas {
  position: absolute;
  width: 100%;
  height: 100%;
}

.MapTransferCanvas {
  position: absolute;
  z-index: 2000;
  width: 100%;
  height: 100%;
}

.MapCanvas__spinnerContainer {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.MapCanvas__spinner {
  color: white;
}

@media only screen and (min-width: $breakpoint-tablet-large) {
  .MapBox {
    display: flex;
    flex: 1;
    width: calc(100% - #{$desktop-left-pane-width});
    margin-left: $desktop-left-pane-width;
  }

  .MapCanvas {
    height: calc(100% - #{$header-height});
    margin-top: $header-height;
  }
}

@import '~@hse-design/core/src/base';

.MapperAuth__container {
  max-width: 380px;
}

.MapperAuth__title {
  margin-bottom: $spacing-small-2x;
}

.MapperAuth__error {
  margin: $spacing-small-3x 0;
  color: $color-red;
}

.MapperAuth__loginButton {
  margin-top: $spacing-small-2x;
}

@import '~@hse-design/core/src/base';

%basePopup {
  z-index: $z-index-popper;
  border-radius: $radius-small;
  background: $color-background-white;
  box-shadow: $shadow-default;
}

.BasePopup {
  @extend %basePopup;
  position: absolute;
  top: 140px;
  bottom: 100px;
  left: 10px;
  overflow-y: scroll;
  width: 340px;
  min-height: 200px;
}

.BasePopup__Container {
  padding: $spacing-small-x;
}

.BasePopup__LoaderContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.BasePopup_loading {
  pointer-events: none;
}

.BasePopup__CloseContainer {
  position: sticky;
  top: $spacing-small-3x;
  right: 0;
  height: 0; // Трюк для того, чтобы контейнер не занимал места
}

.BasePopup__CloseButton {
  margin: 0 $spacing-small-3x 0 auto;
}

.BasePopup__Actions {
  display: flex;
  flex-direction: column;
}

.BasePopup__Action:not(:last-child) {
  margin-bottom: $spacing-small-3x;
}

.BasePopup__Title {
  @include text_caption_large_bold;
  margin-top: $spacing-small-3x * 0.5;
  margin-bottom: $spacing-small-3x;
}

.BasePopup__Header {
}

.BasePopup__Footer {
}

.BasePopup__Content {
}

@import 'node_modules/@hse-design/core/src/base';
@import 'src/vars';

.LangSwitch {
  @extend %flex;
  position: relative;
  align-items: center;
  padding: $spacing-small-x $spacing-small;
  cursor: pointer;

  &:hover {
    background-color: $color-background-gray-1;
  }
}
.LangSwitch__text {
  @include text_paragraph_large;
  margin-left: $spacing-small-x;
}
.LangSwitch__radio {
  position: absolute;
  top: calc(50% - #{$sizing-16} / 2);
  right: $spacing-medium;
}
.LangSwitch__divider {
  width: calc(100% - #{$spacing-small} * 2);
  margin: 0 $spacing-small;
}

@import 'src/vars';

.RoomExtSection {
  margin-right: calc(-1 * var(--nav-box-side-padd));
  margin-left: calc(-1 * var(--nav-box-side-padd));
  padding: $spacing-small var(--nav-box-side-padd);

  &:not(:last-child) {
    border-bottom: 1px solid $color-background-gray-1;
  }
}

.RoomExtSection__title {
  @include text_caption_large_bold;
  margin-bottom: $spacing-small-x;
  color: $color-gray-dusk-3;
}

.NotFound {
  padding-top: 10px;
}

.head {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.head__back {
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}

.back__img {
  height: 20px;
}

.head__info {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.info__title {
  font-size: 17px;
  font-weight: bold;
  line-height: 20px;
  line-height: 1.45;
}

.info__desc {
  margin-top: 5px;
  color: #adaeaf;
  font-size: 13px;
}

.actions {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.actions__button {
  min-width: 170px;
  padding: 10px 20px;
  text-align: center;
  letter-spacing: 0.4px;
  color: #1758da;
  border: solid 2px #1758da;
  border-radius: 4px;
  font-size: 15px;
  font-weight: bold;

  &:hover,
  &:active {
    cursor: pointer;
    color: white;
    background-color: #1758da;
  }
}

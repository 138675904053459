@import 'src/vars';

.BottomSheet {
  [data-rsbs-header] {
    padding-right: var(--nav-box-side-padd);
    padding-bottom: 8px;
    padding-left: var(--nav-box-side-padd);
    border-bottom: none;
    box-shadow: none;
  }

  [data-rsbs-overlay],
  [data-rsbs-backdrop],
  &[data-rsbs-root]::after {
    z-index: $z-index-bottom-sheet;
  }
}

@import './tokens';
@import './colors';

/** @deprecated */
$font-helvetica: 'helvetica';

/** @deprecated */
$font-futura: 'futura';

/** @deprecated */
$fonts-default: $font-helvetica, 'Arial', 'Tahoma', sans-serif;

$mobile-nav-height: 50px;

$header-height: 56px;
$desktop-left-pane-width: 400px;

@function invert($v) {
  @return calc(-1 * #{$v});
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

@mixin horizontallyCenteredImage {
  position: absolute;
  top: 0;
  right: auto;
  left: 50%;
  height: 100%;
  transform: translateX(-50%);
}

@mixin verticallyCenteredImage {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}

@mixin clickableIcon {
  svg {
    color: $color-base-black;
  }

  &:hover {
    svg {
      color: $color-brand !important;
    }
  }
}

/**
 * Breakpoints
 *
 * В навигации по историческим причинам только два брейкпоинта: mobile и desktop.
 * При соотнесении с ДС, mobile это `< tablet-large`, а desktop это `>= tablet-large`.
 */

@mixin bp-mobile-down {
  @include respond-down('tablet-large') {
    @content;
  }
}

/** @deprecated */
%flex {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
}

/** @deprecated */
%flex-c {
  display: flex;
  align-items: center;
  justify-content: center;
}

/** @deprecated */
%flex-btw {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/** @deprecated */
%clear {
  &::before,
  &::after {
    display: table;
    visibility: hidden;
    clear: both;
    height: 0;
    content: '';
    opacity: 0;
  }
}

/** @deprecated */
%gradient-blue {
  background: -moz-linear-gradient(
    left,
    rgba($col-blue, 1) 0%,
    rgba($col-blue, 0.6) 100%
  );
  background: -webkit-linear-gradient(
    left,
    rgba($col-blue, 1) 0%,
    rgba($col-blue, 0.6) 100%
  );
  background: linear-gradient(
    to right,
    rgba($col-blue, 1) 0%,
    rgba($col-blue, 0.6) 100%
  );
  filter: progid:dximagetransform.microsoft.gradient( startColorstr='#1758da', endColorstr='#991758da',GradientType=1 );
}

/** @deprecated */
%gradient-green {
  background: -moz-linear-gradient(
    left,
    rgba($col-green, 1) 0%,
    rgba($col-green-light, 0.6) 100%
  );
  background: -webkit-linear-gradient(
    left,
    rgba($col-green, 1) 0%,
    rgba($col-green-light, 0.6) 100%
  );
  background: linear-gradient(
    to right,
    rgba($col-green, 1) 0%,
    rgba($col-green-light, 0.6) 100%
  );
  filter: progid:dximagetransform.microsoft.gradient( startColorstr='#1a7b71', endColorstr='#992a867d',GradientType=1 );
}

/** @deprecated */
%gradient-purple {
  background: -moz-linear-gradient(
    left,
    rgba($col-purple, 1) 0%,
    rgba($col-purple, 0.6) 100%
  );
  background: -webkit-linear-gradient(
    left,
    rgba($col-purple, 1) 0%,
    rgba($col-purple, 0.6) 100%
  );
  background: linear-gradient(
    to right,
    rgba($col-purple, 1) 0%,
    rgba($col-purple, 0.6) 100%
  );
  filter: progid:dximagetransform.microsoft.gradient( startColorstr='$col-purple', endColorstr='#999c61b2',GradientType=1 );
}

/** @deprecated */
%gradient-red {
  background: -moz-linear-gradient(
    left,
    rgba($col-red, 1) 0%,
    rgba($col-red, 0.6) 100%
  );
  background: -webkit-linear-gradient(
    left,
    rgba($col-red, 1) 0%,
    rgba($col-red, 0.6) 100%
  );
  background: linear-gradient(
    to right,
    rgba($col-red, 1) 0%,
    rgba($col-red, 0.6) 100%
  );
  filter: progid:dximagetransform.microsoft.gradient( startColorstr='#eb4146', endColorstr='#99eb4146',GradientType=1 );
}

/** @deprecated */
%gradient-yellow {
  background: -moz-linear-gradient(
    left,
    rgba($col-yellow, 1) 0%,
    rgba($col-yellow, 0.6) 100%
  );
  background: -webkit-linear-gradient(
    left,
    rgba($col-yellow, 1) 0%,
    rgba($col-yellow, 0.6) 100%
  );
  background: linear-gradient(
    to right,
    rgba($col-yellow, 1) 0%,
    rgba($col-yellow, 0.6) 100%
  );
  filter: progid:dximagetransform.microsoft.gradient( startColorstr='#e9a42a', endColorstr='#99e9a42a',GradientType=1 );
}

/** @deprecated */
%svg-icon {
  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

/** @deprecated */
%link-reset {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  outline: none;
}

/** @deprecated */
%input-reset {
  margin: 0;
  padding: 0;
  resize: none;
  border: 0;
  border-radius: 0;
  outline: none;
  background: none;
  font-family: $fonts-default;
  font-size: 17px;
  appearance: none;
  &:focus {
    @include placeholder {
      text-overflow: clip;
      color: transparent !important;
    }
  }
  @include placeholder {
    transition: color 0.3s;
    text-overflow: ellipsis;
    color: $col-gray-placeholder;
  }
}

@import 'node_modules/@hse-design/core/src/base';
@import 'src/common/components/MapControls/MapControls.module';

.CampusControl {
  position: absolute;
  right: 0;
  width: 100%;
  pointer-events: auto;
}
.CampusControl__selectedCampus {
  @include text_caption_medium_bold;
  @extend %control-default;
  position: absolute;
  top: $spacing-small-3x;
  right: $spacing-small-3x;
  min-width: fit-content;
  padding: 14px 25px;
  cursor: pointer;
  text-decoration: none;
  color: $color-black;
}

.CampusControl__dropdown {
  @extend %control-default;
  position: absolute;
  top: calc(#{$control-size} + #{$spacing-small-2x} * 2);
  right: $spacing-small-2x;
  width: 100%;
  padding: $spacing-small 0 $spacing-small-x;
}
.CampusControl__dropdownHeader {
  @include text_heading_h4;
  padding: 0 $spacing-small $spacing-small-x;
}
.CampusControl__dropdownClose {
  @include clickableIcon;
  position: absolute;
  top: $spacing-small;
  right: $spacing-small;
}

@media only screen and (min-width: $breakpoint-tablet-large) {
  .CampusControl {
    width: 360px;
  }
  .CampusControl__selectedCampus {
    top: $spacing-small-2x;
    right: $spacing-small-2x;
  }
}

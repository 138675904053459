@import 'src/vars';

.RouteSearchAction {
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 176px;
  padding: 0 $spacing-small-3x;
  white-space: nowrap;
  color: $col-blue;
}

.RouteSearchAction__icon {
  min-width: $sizing-24;
  margin-top: -2px;
  margin-right: $spacing-small-3x;
  vertical-align: middle;
  color: $col-blue;
}

.RouteSearchAction__text {
  @include text_caption_small_regular;
  white-space: nowrap;
}

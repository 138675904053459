@import 'src/vars';

.BottomSheetTitle {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}

.BottomSheetTitle__name {
  @include text_caption_x_large_bold;
  text-align: left;
  color: $color-primary-midnight;
}

.BottomSheetTitle__close {
}

@import 'node_modules/@hse-design/core/src/base';
@import 'src/common/components/MapControls/MapControls.module';

.ZoomAction {
  @extend %control-container-default;
  position: absolute;
  right: $spacing-small-3x;
  bottom: 0;
  pointer-events: auto;
}
.ZoomAction__button {
  @include clickableIcon;
  @extend %flex-c;
  padding: $spacing-small-2x;
  transition: color 0.2s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.ZoomAction__divider {
  width: calc(100% - #{$spacing-small-3x} * 2);
  margin: 0 $spacing-small-3x;
}

@media only screen and (min-width: $breakpoint-tablet-large) {
  .ZoomAction {
    right: $spacing-small-2x;
    bottom: $spacing-small;
  }
}

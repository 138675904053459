@import 'src/vars';

.RouteSearchPoint {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.RouteSearchPoint__icon {
  flex-basis: auto;
  min-width: $spacing-medium;
  margin-right: $spacing-small-2x;
}

.RouteSearchPoint__search {
  flex-grow: 1;
  width: 0;
  min-width: unset;
}

.RouteSearchPoint__search__input {
  &::-webkit-search-cancel-button {
    display: none;
  }
}

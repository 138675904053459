@import 'src/vars';

.LeftPaneTitle {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}

.LeftPaneTitle__name {
  @include text_heading_h4;
  text-align: left;
  color: $color-primary-midnight;
}

.LeftPaneTitle__close {
}

@import 'src/vars';
@import 'node_modules/@hse-design/core/src/base';

.RouteGeneral {
  @extend %flex;
  position: relative;
  align-items: flex-start;
  padding: 16px 0 18px;
}
.RouteGeneral__icon {
  @extend %svg-icon;
  width: 24px;
  min-width: 24px;
  height: 24px;
  margin-right: 12px;
}
.RouteGeneral__info {
  width: 100%;
}
.RouteGeneral__title {
  padding-right: 16px + 24px;
  color: $col-black;

  p {
    font-weight: bold;
    line-height: 24px;
  }
}
.RouteGeneral__ext {
  margin-top: 2px;
  color: $col-gray-text;

  p {
    font-size: 14px;
    line-height: 16px;
  }
}
.RouteGeneral__close {
  @extend %svg-icon;
  position: absolute;
  top: 14px;
  right: 0;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.RouteGeneralActions {
  padding: 0 (24px + 12px);

  button {
    width: 100%;
  }
}
.RouteGeneralActions__action {
  flex: 1;
}

.RouteStepList {
}
.RouteStepList__item {
  &:last-child {
    .RouteStep::after {
      display: none;
    }
  }
}

.RouteStep {
  @extend %flex;
  position: relative;
  align-items: flex-start;
  padding: 18px var(--nav-box-side-padd) 18px;
  transition: background 0.2s;
  color: $col-black;

  &._start,
  &._end {
    .RouteStep__title {
      color: $col-blue;

      p {
        font-family: $font-futura;
        font-size: 22px;
        font-weight: 600;
        line-height: 32px;
      }
    }
    .RouteStep__icon {
      width: 32px;
      min-width: 32px;
      height: 32px;
    }
  }

  &._selectable {
    cursor: pointer;
    &:hover,
    &._active {
      background: $col-gray-xlight;
    }
  }
}
.RouteStep__icon {
  @extend %svg-icon;
  width: 24px;
  min-width: 24px;
  height: 24px;
  margin-right: 16px;
}
.RouteStep__info {
  width: 100%;
}
.RouteStep__title {
  p {
    font-weight: bold;
    line-height: 24px;
  }
}
.RouteStep__transferData {
  margin-top: 16px;
}
.RouteStep__transferStepName {
  @include text_caption_medium_bold;
  padding-right: 16px;
  padding-bottom: 16px;
  color: $color-dusk;
}
.RouteStep__transferStepAddress {
  @include text_caption_medium_regular;
  color: $color-dusk;
}
.RouteStep__yaMapLink {
  @include text_caption_medium_bold;
  white-space: nowrap; // на 320 иконка съезжает
  text-decoration: none;
  color: $color-brand;
}
.RouteStep__yaMapLinkIcon {
  position: relative;
  bottom: 1px;
  left: 1px;
  color: $color-brand;
}

.PanelSection {
  position: relative;

  &:first-child {
    .SectionDivider {
      display: none;
    }
  }
}

.PanelSection__content {
  padding: 24px 0 40px;
}

.PanelSection__divider_outset {
  margin-right: calc(-1 * var(--nav-box-side-padd));
  margin-left: calc(-1 * var(--nav-box-side-padd));
}

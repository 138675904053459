@import '~@hse-design/core/src/base';

$accent-red-color: $color-accent-red-3;
$light-brand-color: $color-brand-2;
$lighter-brand-color: $color-brand-4;
$brand-color: $color-brand;
$error-color: $color-accent-red;
$success-color: $color-accent-green;
$danger-color: $color-accent-red;
$gray-color: $color-gray-dusk-4;

:export {
  accentRedColor: $accent-red-color;
  lightBrandColor: $light-brand-color;
  lighterBrandColor: $lighter-brand-color;
  brandColor: $brand-color;
  errorColor: $error-color;
  successColor: $success-color;
  dangerColor: $danger-color;
  grayColor: $gray-color;
}

@import 'src/vars';

.Loader {
  --height: 80px;
  position: relative;
  //width: 100%;
  text-align: center;
}

.Loader_fullWidth {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.Loader__loader {
  position: relative;
  display: inline-block;
  width: 80px;
  height: var(--height, 80px);
}

.Loader__loaderInner {
  position: absolute;
  top: calc((var(--height) - 12px) / 2);
  width: 12px;
  height: 12px;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
  border-radius: 50%;
  background: $col-blue;

  &:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  &:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  &:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  &:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
}

@import 'src/vars';

.RoomExtStaff {
  > *:not(:first-child) {
    margin-top: $spacing-small;
  }
}

.RoomExtStaff__item {
}

@import 'src/vars';

.Room {
  padding: 12px 0 14px;
  cursor: pointer;
}

.Room__titleBlock {
  display: flex;
  justify-content: space-between;
}

.Room__desc {
  @include text_caption_small_regular;
  margin-top: 2px;
  color: $col-black;
}

.titleBlock__title,
.titleBlock__distance {
  @include text_caption_large_bold;
}

.titleBlock__title {
  color: $color-brand;
}

.titleBlock__distance {
  margin-left: 8px;
  white-space: nowrap;
  font-size: 15px;
}

@import 'src/vars';

.DesktopSearchPanel {
}

.DesktopSearchPanel__search {
  margin-bottom: $spacing-medium;
}

.DesktopSearchPanel__route {
  margin-bottom: $spacing-medium-x;
}

.DesktopSearchPanel__categories {
  margin-bottom: $spacing-medium;
}

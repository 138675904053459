@import '../../../../vars';

.RoomInfoHead {
  position: relative;
  display: flex;
  flex-direction: column;
}

.RoomInfoHead__title {
  @include text_heading_h4;
  color: $color-brand;
}

.RoomInfoHead__description {
  @include text_caption_medium_regular;
}

.RoomInfoHead__actions {
  margin-top: 32px;
}

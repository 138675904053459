@import 'src/vars';

.MyLocationPanelContent {
}

.MyLocationPanelContent__actions {
  margin-top: $spacing-medium;
}

.MyLocationPanelContent__room {
  @include text_caption_medium_regular();
  margin-top: $spacing-small-2x;
  margin-bottom: $spacing-small-3x;
  color: #0f0f14;
}

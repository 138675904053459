@import 'node_modules/@hse-design/core/src/base';
@import 'src/vars';

$control-size: $sizing-48;
$control-item-size: $sizing-32;

%control-default {
  border-radius: $radius-large;
  background: $color-base-white;
  box-shadow: #{$shadow-hover};
}
%control-container-default {
  @extend %control-default;
  overflow: hidden;
  width: $control-size;
  min-height: $control-size;
}

.MapControls {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 70vh;
  pointer-events: none;
}

@media only screen and (min-width: $breakpoint-tablet-large) {
  .MapControls {
    height: calc(100% - #{$header-height});
    max-height: 100vh;
    margin-top: $header-height;
  }
}

@import '../../../../vars';

.RoomPanel {
}

.RoomPanel__content {
  padding-top: 0;
}

.RoomPanel__title {
  @include text_caption_x_large_bold;
  color: $color-brand;
}

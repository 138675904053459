@import 'src/vars';

$overlayBackgroundColor: rgba(0, 0, 0, 0.7);

.ModalOverlay {
  position: fixed;
  z-index: $z-index-modal;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.75);
}

.Modal {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: auto;
  color: white;
  outline: none;
  background: black;
}

.Modal__videoContainer {
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: black;
}

.Modal__video {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}

.Modal__overlayMessage {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 25%;
  padding: 16px;
  text-align: center;
  color: white;
  background: white;
  background: $overlayBackgroundColor;
}

.Modal__overlayTop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 25%;
  min-height: 64px;
  background: $overlayBackgroundColor;
}

.Modal__close {
  @extend %input-reset;
  position: absolute;
  z-index: 10;
  top: 16px;
  right: 16px;
  width: 32px;
  height: 32px;
  cursor: pointer;
  color: white;
}

.QrErrorMessage {
  max-width: 400px;
  margin: auto;
  text-align: center;
}

.QrErrorMessage__title {
  margin-bottom: 8px;
  font-weight: bold;
}

.Modal__container {
  display: flex;
  height: 100%;
  padding: 16px;
}

@import '~@hse-design/core/src/base';

.MapperHints {
  position: absolute;
  z-index: 10000000;
  left: 50%;
  padding: 16px;
  transform: translateX(-50%);
  pointer-events: none;
  color: black;
  background: transparent;
  box-shadow: none;
  font-size: 16px;
}

.MapperHints_isActive {
  pointer-events: all;
  background: white;
  box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.1);
}

.MapperHints__title {
  @include text_caption_large_bold;
}

.MapperHints__description {
  @include text_caption_small_regular;
  margin-top: $spacing-small-3x * 0.25;
}

.MapperHints__cancelButton {
  margin-top: $spacing-small-3x;
}

.OfflineModeModal {
  :global(.hse-BaseModal__wrapper) {
    min-height: unset;
  }
}

.OfflineModeModal__actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.OfflineModeModal__spinner {
  display: block;
  margin: 24px auto;
}

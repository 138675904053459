@import 'src/vars';

.RoomList__group {
}

.RoomList__campusName {
  @include text_caption_large_bold;
  opacity: $opacity-60;
  color: $color-dusk;
}

.RoomList__group {
  margin-bottom: 24px;
}

.RoomList__listItem {
  border-bottom: 1px solid $color-background-gray-1;

  &:last-child {
    border-bottom: 0;
  }
}

@import 'src/vars';

.RoomPerson {
  @include text_caption_small_regular;
}

.RoomPerson__name {
  @include text_caption_medium_bold;
}

.RoomPerson__title {
  @include text_caption_small_regular;
  margin-top: $spacing-small-3x * 0.5;
}

.RoomPerson__contact {
  margin-top: $spacing-small-3x * 0.25;
}

.RoomPerson__profileLink {
  margin-top: $spacing-small-3x;
}

.RoomPerson__structure {
  margin-top: $spacing-small-3x * 0.25;
  color: $color-gray-dusk-2;
}

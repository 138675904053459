@import 'node_modules/@hse-design/core/src/base';

.CampusesListItem {
  position: relative;
  padding: $spacing-small-x $spacing-small * 2 $spacing-small-x $spacing-small;
  cursor: pointer;

  &:hover {
    background-color: $color-background-gray-1;
  }
}
.CampusesListItem__name {
  @include text_caption_medium_bold;
  padding-bottom: 4px;
  color: $color-brand;
}
.CampusesListItem__address {
  @include text_caption_small_regular;
}
.CampusesListItem__radio {
  position: absolute;
  top: calc(50% - #{$sizing-16} / 2);
  right: $spacing-small;
}
.CampusesListItem__divider {
  width: calc(100% - #{$spacing-small} * 2);
  margin: 0 $spacing-small;
}

@import '~@hse-design/tokens/lib/scss/breakpoints';
@import '~@hse-design/tokens/lib/scss/sidebarWidth';
@import '~@hse-design/core/src/base';

$spacing-20: $spacing-small - 4px;
$font-size-20: 20px;
$line-height-28: 28px;

$z-index-nav-box: 9999;
$z-index-bottom-sheet: $z-index-nav-box;
$z-index-side-nav: 10000;
$z-index-pin: 10000;
$z-index-mobile-nav: 10001;
$z-index-header: 10002;
$z-index-mobile-screen: 10003;

@import '../../vars';

@font-face {
  font-family: $font-helvetica;
  src: url('./HelveticaNeueCyr-Bold.eot');
  src: url('./HelveticaNeueCyr-Bold.eot?#iefix') format('embedded-opentype'),
    url('./HelveticaNeueCyr-Bold.woff2') format('woff2'),
    url('./HelveticaNeueCyr-Bold.woff') format('woff'),
    url('./HelveticaNeueCyr-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: $font-helvetica;
  src: url('./HelveticaNeueCyr-Roman.eot');
  src: url('./HelveticaNeueCyr-Roman.eot?#iefix') format('embedded-opentype'),
    url('./HelveticaNeueCyr-Roman.woff2') format('woff2'),
    url('./HelveticaNeueCyr-Roman.woff') format('woff'),
    url('./HelveticaNeueCyr-Roman.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: $font-futura;
  src: url('./FuturaPT-Demi.eot');
  src: url('./FuturaPT-Demi.eot?#iefix') format('embedded-opentype'),
    url('./FuturaPT-Demi.woff2') format('woff2'),
    url('./FuturaPT-Demi.woff') format('woff'),
    url('./FuturaPT-Demi.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

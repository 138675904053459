@import 'node_modules/@hse-design/core/src/base';
@import 'src/vars';

.SideMenu__overlay {
  position: fixed;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  transition: opacity 0.3s ease, z-index 0s 0.3s;
  pointer-events: none;
  opacity: 0;
  background: rgba($color-overlay-dark, $opacity-40);

  &._active {
    z-index: var(--zindex-side-nav);
    transition-delay: 0s;
    pointer-events: auto;
    opacity: 1;
  }
}

.SideMenu {
  position: fixed;
  z-index: var(--zindex-side-nav);
  top: 0;
  bottom: 0;
  left: 0;
  width: var(--side-menu-width);
  max-width: 100%;
  padding-bottom: var(--mobile-nav-height);
  transition: transform 0.3s ease;
  transform: translateX(-100%);
  background: $color-white;
  will-change: transform;

  &._active {
    transform: translateX(0);
  }
}

.SideMenu__scroll {
  overflow: hidden;
  overflow-y: auto;
  height: 100%;
}

.SideMenu__header {
  display: flex;
  align-items: center;
  padding: $spacing-small;
}
.SideMenu__headerLogo {
  margin-right: $spacing-small-x;
}
.SideMenu__headerTitle {
  @include text_caption_large_bold;
  color: $color-text-brand-dark;
}

@import 'node_modules/@hse-design/core/src/base';
@import 'src/vars';

.MobileScreen {
  position: fixed;
  z-index: var(--zindex-mobile-screen);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $color-base-white;
}

.MobileScreen_withActions {
  padding-bottom: $spacing-large-x;
}

.MobileScreen__arrowBack {
  @include clickableIcon;
  position: absolute;
  top: $spacing-small-x;
  left: $spacing-small;
}

.MobileScreen__title {
  @include text_caption_x_large_bold;
  padding: $spacing-small-x 0 $spacing-medium;
  text-align: center;
  line-height: 24px;
}

.MobileScreen__actions {
  position: absolute;
  bottom: $spacing-small;
  left: $spacing-small;
  display: flex;
  align-items: stretch;
  flex-direction: row;
  justify-content: space-around;
  width: calc(100% - #{$spacing-small} * 2);
  margin: -8px;
  text-align: center;

  > * {
    flex-grow: 1;
    margin: 8px;
  }
}

@import 'src/vars';

.SideMenuLink {
  display: flex;
  align-items: center;
  padding: $spacing-small;
  text-decoration: none;
}

.SideMenuLink__icon {
  margin-right: $spacing-small-x;
  color: $color-gray-dusk;
}

.SideMenuLink__text {
  @include text_caption_medium_bold;
  color: $color-gray-dusk;
}

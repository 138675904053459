.Page {
  position: relative;
  overflow: hidden;
  height: 100vh;
}

.Device {
  position: fixed;
  z-index: 100000;
  bottom: 0;
  left: 0;
  color: #fff;
  background: #000;
}

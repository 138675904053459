@import 'node_modules/@hse-design/core/src/base';
@import 'src/common/components/MapControls/MapControls.module';

$pin-size: $spacing-large;

.FloorControl {
  @extend %control-container-default;
  position: absolute;
  bottom: 0;
  left: $spacing-small-3x;
  pointer-events: auto;
}

.FloorControl_additionalBottomMargin {
  bottom: $pin-size + $spacing-small-3x;
}

.FloorControl__scroll {
  overflow: hidden;
  overflow-y: auto;
  max-height: 168px;

  &::-webkit-scrollbar {
    display: none;
  }
}
.FloorControlItem {
  @extend %flex-c;
  margin: $spacing-small-3x 0;
  cursor: pointer;
  transition: color 0.2s;
  text-align: center;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  span {
    @include text_caption_small_bold;
    display: block;
    width: $control-item-size;
    height: $control-item-size;
    transition: background 0.2s;
    border-radius: $radius-medium;
    line-height: $control-item-size;
  }

  &:hover {
    color: $color-brand;
  }
  &._active {
    color: $color-white;
    span {
      background: $color-brand;
    }
  }
}

@media only screen and (min-width: $breakpoint-tablet-large) {
  .FloorControl {
    top: 50%;
    right: $spacing-small-2x;
    bottom: initial;
    left: initial;
    transform: translate(0, -50%);
  }

  .FloorControl__scroll {
    max-height: 200px;
  }
}

.SearchPanelContent {
}

.SearchPanelContent__input {
  margin-bottom: 20px;
}

.SearchPanelContent__categories {
  margin-bottom: 24px;
}

.SearchPanelContent__searchResults {
}

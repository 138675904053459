@import '../../../../vars';

.NodeMarkerIcon {
  @extend %flex;
  position: absolute;
  top: 50%;
  left: 50%;
  align-items: center;
  box-sizing: border-box;
  width: 20px;
  min-width: 20px;
  height: 20px;
  transform: translateX(-50%) translateY(-50%);
  border-radius: 50%;
  background: $color-brand-2;
}

.NodeMarkerIcon__icon {
  @extend %svg-icon;
  color: $col-white;
}

.NodeMarkerIcon._selected {
  background: $color-brand;
}

.NodeMarkerIcon._error {
  background: $color-error;

  &._selected {
    background: $color-error-dark;
  }
}

.NodeMarkerIcon__text {
  //position: absolute;
  //top: 50%;
  //left: 100%;
  //margin-top: -12px;
  margin-left: 2px;
  padding-right: 1px;
  color: $col-white;
  font-size: 16px;

  font-weight: 700;
  line-height: 24px;
}

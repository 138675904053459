@import '../../vars';

.RoomSelectedParent {
  padding-bottom: var(--nav-box-side-padd);
}
.RouteMapper__inputs {
  padding-left: 6px;
}
.RoomList {
  // height: 100%;
}
.RouteSteps {
  margin: 0 invert(var(--nav-box-side-padd));
}

.RouteStepsHead {
  position: relative;
  margin: 0 invert(var(--nav-box-side-padd));

  .RouteStep {
    padding-right: 60px;
    color: $col-blue;
  }
}
.RouteStepsHead__close {
  @extend %svg-icon;
  position: absolute;
  top: 14px;
  right: 20px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.RouteStepsHeadSwiper {
  ul {
    @extend %flex;
    position: absolute;
    top: 0;
    left: 50%;
    align-items: center;
    margin-top: 8px;
    transform: translateX(-50%);
    border-radius: 2px;
  }
  li {
    margin-right: 6px;
    &:last-child {
      margin-right: 0;
    }
  }
}
.RouteStepsHeadSwiper__dot {
  position: relative;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: $col-gray-light;

  &::after {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 4px;
    height: 4px;
    content: '';
    transition: width ease 0.2s, background 0.2s;
  }

  &._active {
    &::after {
      width: 4px + 6px + 4px;
      border-radius: 4px;
      background: $col-blue;
    }
    &._last {
      &::after {
        width: 4px;
      }
    }
  }
}

.RoomNavBox__head {
  padding: 0 var(--nav-box-side-padd);
  padding-bottom: 16px;

  &._opened {
    padding-bottom: 0;
  }
}
.RoomNavBox__content {
  overflow: auto;
  cursor: auto;
  user-select: text;

  &._opened {
    padding-top: 0;
    &::before {
      display: none;
    }
  }
}
.RoomNavBox__roomExt {
  margin-top: -24px;
}

.LinkSection {
  padding-top: 24px;
  padding-bottom: 24px;
}

.SearchNavBox {
  .Loader {
    padding-top: 20px;
  }
}
.RouteNavBox {
  .Loader {
    padding-top: 20px;
  }
}

.NotFoundNavBox__head,
.SelectOnMapNavBox_head {
  padding-bottom: 52px;
}

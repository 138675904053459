@import 'src/vars';

.MobileNav {
  position: fixed;
  z-index: var(--zindex-mobile-nav);
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(#{$mobile-nav-height} + var(--safe-area-inset-bottom));
  padding-bottom: var(--safe-area-inset-bottom);
  transition: transform 0.3s;
  transform: translateY(100%);
  background: $col-white;
  box-shadow: 0 2px 8px rgba(106, 122, 152, 0.15);
}

.MobileNav_active {
  transform: translateY(0);
}

.MobileNav__list {
  @extend %flex;
  align-items: center;
  justify-content: space-around;
  height: 100%;
}

.MobileNavButton {
  transition: color 0.2s;
  text-align: center;
  color: $col-gray-dark;
}

.MobileNavButton__icon {
  @extend %svg-icon;
  display: block;
  width: 24px;
  height: 24px;
  margin: 0 auto 2px;
}

.MobileNavButton__text {
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
}

.MobileNavButton_active {
  color: $col-blue;
}

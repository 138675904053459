@import '~@hse-design/core/src/base';

.PopupProp {
  @include text_caption_small_regular;
  margin-bottom: $spacing-small-3x;
}

.PopupProp__Label {
  @include text_caption_small_bold;
  display: block;
  margin-bottom: $spacing-small-3x * 0.5;
}

.PopupProp__Value {
  display: flex;
  flex-grow: 1;
}

.PopupGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.CategoriesSlider {
  overflow-x: scroll;
  overflow-y: hidden;
  width: calc(100% + 40px);
  margin: 0 calc(-1 * var(--nav-box-side-padd));
  padding: 8px var(--nav-box-side-padd) 0 var(--nav-box-side-padd);
  white-space: nowrap;
}

.CategoriesSlider__item {
  &:not(:last-child) {
    margin-right: 8px;
  }
}

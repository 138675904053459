/** @deprecated */
$col-bg: #f5f7fa;

/** @deprecated */
$col-white: #fff;

/** @deprecated */
$col-black: #000;

/** @deprecated */
$col-gray-xlight: #f5f6f7;

/** @deprecated */
$col-gray-light: #e1e3e6;

/** @deprecated */
$col-gray: #c6c8cc;

/** @deprecated */
$col-gray-dark: #333;

/** @deprecated */
$col-blue: #1758da;

/** @deprecated */
$col-green: #1a7b71;

/** @deprecated */
$col-green-light: #2a867d;

/** @deprecated */
$col-purple: #9c61b2;

/** @deprecated */
$col-red: #eb4146;

/** @deprecated */
$col-yellow: #e9a42a;

/** @deprecated */
$col-gray-text: #a3a4a6;

/** @deprecated */
$col-gray-placeholder: #adaeaf;

/** @deprecated */
$bg-color-blue-cats-hover: #b3ccff;

/** @deprecated */
$bg-color-blue-cats: #d9e6ff;

/** @deprecated */
$color-blue-cats: #0056ff;

$color-primary-midnight: #0f0f14;

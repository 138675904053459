@import 'src/vars';

.LeftPane {
  position: fixed;
  top: $header-height;
  overflow-y: scroll;
  width: $desktop-left-pane-width;
  height: calc(100% - #{$header-height});
  background: $color-light;
  box-shadow: 20px 0 35px rgba(0, 0, 0, 0.2);
}

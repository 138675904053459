@import '~@hse-design/core/src/base';

.NotFoundPage {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $spacing-medium;
}

.NotFoundPage__text {
  @include text_heading_h1;
}

.NotFoundPage__description {
  @include text_caption_large_regular;
}

.NotFoundPage__children {
  margin-top: $spacing-small-3x;
}

@import 'src/vars';

.RouteSearch {
  display: flex;
  align-items: stretch;
  flex-direction: column;
}

.RouteSearch__head {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.RouteSearch__points {
  flex-grow: 1;

  > *:not(:last-child) {
    margin-bottom: $spacing-small-x;
  }
}

.RouteSearch__swap {
  flex-basis: auto;
  flex-grow: 0;
  margin-left: $spacing-small-3x;
}

.RouteSearch__actions {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: $spacing-small-2x;

  > * {
    flex-basis: 0;
    flex-grow: 1;
  }
}

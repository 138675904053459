@import 'src/vars';

.ActionsList {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: -$spacing-small-3x;

  > * {
    flex-grow: 1;
    margin: $spacing-small-3x;
  }
}

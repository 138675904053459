@import './vars';

:root {
  --btn-height: 56px;
  --search-height: 48px;
  --nav-box-side-padd: 20px;
  --side-menu-width: 287px;

  --zindex-nav-box: #{$z-index-nav-box};
  --zindex-side-nav: #{$z-index-side-nav};
  --zindex-pin: #{$z-index-pin};
  --zindex-mobile-nav: #{$z-index-mobile-nav};
  --zindex-header: #{$z-index-header};
  --zindex-mobile-screen: #{$z-index-mobile-screen};

  --safe-area-inset-bottom: env(safe-area-inset-bottom, 0);
}

html {
  box-sizing: border-box;
  font-family: $fonts-default;
  font-size: 16px;
}
html,
body {
  position: fixed;
  overflow: hidden;
  width: 100%;
  margin: 0;
  padding: 0;
  user-select: none;
  color: $col-black;
  background: $col-bg;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  overscroll-behavior: none;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.test {
  content: 'something';
  color: white;
  background: red;
  font-size: 18px;
}

@import '../../vars';

.MapperPage {
  position: relative;
  width: 100%;
  height: 100vh;

  & :global {
    .leaflet-image-layer {
      user-select: none;
    }
  }
}

.MapperPage__floorControl {
  z-index: 1000;
  transform: translateY(-50%);
}

.MapperPage__campusControl {
  z-index: 1000;
}

.MapperPage__Map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: default;
  background: none;

  > div:nth-child(2) {
    display: none;
  }

  //& :global {
  //  .leaflet-interactive {
  //    transform: translate3d(0,0,0);
  //  }
  //}
}

.MapperPage__Overlay {
}

.MapperPage__progressBar {
  position: absolute;
  z-index: 10000;
  top: 0;
  right: 0;
  left: 0;
}

.MapperPage__Corpuses {
  @extend %basePopup;
  position: absolute;
  top: $spacing-small-3x;
  left: $spacing-small-3x;
}

.Map__loader {
  @extend %flex-c;
  position: absolute;
  z-index: 401;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($col-white, 0.7);
}

%basePopup {
  z-index: 1010;
  border-radius: $radius-small;
  background: $color-background-white;
  box-shadow: $shadow-default;
}

.MapperPage__Menu {
  @extend %basePopup;
  position: absolute;
  bottom: 8px;
  left: 8px;
  display: flex;
  padding: $spacing-small-2x;
}

.MapperPage__Floors {
  @extend %basePopup;
  position: absolute;
  top: $spacing-large;
  left: $spacing-small-3x;
  padding: $spacing-small-3x;
}

.Floors__current {
  margin-right: 8px;
}

.Floors__buttons {
  display: flex;
}

.Floors__button {
  display: inline-flex;
  min-width: 32px;
}

.MapperPage__disconnected {
  position: fixed;
  z-index: 5000;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;

  background: rgba(0, 0, 0, 0.15);

  &:not(._active) {
    display: none;
  }
}

.MapRoom {
  transition: fill 0.2s, fill-opacity 0.2s;
}

.MapRoomTooltip {
  opacity: 0 !important;
  color: $col-gray-dark;
  border: 0;
  background: none;
  box-shadow: none;

  font-family: $font-helvetica;
  font-size: 14px;
  font-weight: 700;

  &._visible {
    opacity: 1 !important;
  }
}

.ModalOverlay {
  position: fixed;
  z-index: 5500;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.75);
}

.NodePopup__Actions {
  margin-top: 8px;
}

.NodePopup__Action {
  margin-bottom: $spacing-small-3x;
}

.PopupProp {
  margin-bottom: $spacing-small-3x;
}

.EdgePopup__CoordinatesTable {
  width: 100%;
  border-collapse: collapse;
}
